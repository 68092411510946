import CollapsableContent from './CollapsableContent';
import React from 'react';
import { EventInfo, EventSeriesProps, EventCreationDetails } from '../pages/calendar/types/eventModel';
import EventForm from '../pages/calendar/components/EventForm';
import {localToGMT} from '../pages/calendar/utils'

interface CollapsableAdminProps {
    title: string,
    startingIsCollapsed: boolean,
    loggedInUser: number,
    authToken: string,
    event: EventInfo,
}

const CollapsableAdmin = (props: CollapsableAdminProps) => {

    const [adminDialog, setAdminDialog] = React.useState<boolean>(false);

    const enableAdminDialog = () => {
        setAdminDialog(true);
    }

    const handleEventFormSubmit = (requestBody:EventCreationDetails | EventSeriesProps | EventInfo)=>{
        const requestOptions = {
          method: 'POST',
          headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody)
        }

        console.log(requestBody);
    
        if(Object.keys(requestBody).includes("startTime") && Object.keys(requestBody).includes("id")){
          const eInfo = requestBody as EventInfo

          //Convert from local to GMT
            const convertedStart = localToGMT(eInfo.startTime,eInfo.startTime,`America/New_York`);
            const convertedEnd = localToGMT(eInfo.endTime,eInfo.endTime,`America/New_York`);
            const newReqBody = {...eInfo, startTime: convertedStart.dateTime, endTime: convertedEnd.dateTime};
            const newRequestOptions = {...requestOptions, body: JSON.stringify(newReqBody)};
    
          // Editing existing event instance
          return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/events/${eInfo.id}`, newRequestOptions)
          .then(res => res.json())
          .then((res) => {
              if (res.result && res.result === `success`) {
                  setAdminDialog(false);
                  return true;
    
              } else {
                  console.error(`Backend Error in SetEvent`);
                  console.error(res)
                  res.reason && console.error(`Reason: ${res.reason}`);
                  return false;
              }
          })
          .catch((reason) => {
              console.error('Fetch Exception in SetEvent')
              console.error(`Reason: ${reason}`)
              return false;
          })
        }
    
        if(Object.keys(requestBody).includes("eventsStart") && Object.keys(requestBody).includes("id")){
          const eSeries = requestBody as EventSeriesProps
          // Editing existing event series

          //Convert from local to GMT
            const convertedStart = localToGMT(eSeries.eventsStart,eSeries.eventStartTime,`America/New_York`);
            const convertedEnd = localToGMT(eSeries.eventsEnd,eSeries.eventEndTime,`America/New_York`);
            const newReqBody = {...eSeries, eventsStart: convertedStart.date, eventStartTime: convertedStart.time, eventsEnd: convertedEnd.date, eventEndTime: convertedEnd.time};
            const newRequestOptions = {...requestOptions, body: JSON.stringify(newReqBody)};
          return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/event_series/${eSeries.id}`, newRequestOptions)
          .then(res => res.json())
          .then((res) => {
              if (res.result && res.result === `success`) {
                  setAdminDialog(false);
                  return true;
    
              } else {
                  console.error(`Backend Error in SetEventSeries`);
                  res.reason && console.error(`Reason: ${res.reason}`);
                  return false;
              }
          })
          .catch((reason) => {
              console.error('Fetch Exception in SetEventSeries')
              console.error(`Reason: ${reason}`)
              return false;
          })
        }
    
        // Creating new event (series)
        return fetch(process.env.REACT_APP_REFLEBULA + '/a1/tga/event/create', requestOptions)
          .then(res => res.json())
          .then((res) => {
              if (res.status && res.status === `success`) {
                  setAdminDialog(false);
                  return true;
              } else {
                  console.error(`Backend Error in CreateEvent`);
                  res.reason && console.error(`Reason: ${res.reason}`);
                  return false;
              }
          })
          .catch((reason) => {
              console.error('Fetch Exception in CreateEvent')
              console.error(`Reason: ${reason}`)
              return false;
          })
      }

    const handleEventDelete = (requestBody:EventInfo|EventSeriesProps)=>{
        if(Object.keys(requestBody).includes("startTime")){
          //delete single instance
          const eInfo = requestBody as EventInfo
          const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
          }
    
          return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/events/${eInfo.id}`, requestOptions)
          .then(res => res.json())
          .then((res) => {
            if (res.result && res.result === `success`) {
                setAdminDialog(false)
                return true;
            } else {
                console.error(`Backend Error in handleEventDelete`);
                res.reason && console.error(`Reason: ${res.reason}`);
                return false;
            }
          })
          .catch((reason) => {
              console.error('Fetch Exception in handleEventDelete')
              console.error(`Reason: ${reason}`)
              return false;
          })
        }else{
          // delete event series
          const eSeries = requestBody as EventSeriesProps
          const requestOptions = {
            method: 'DELETE',
            headers: { 'Authorization': `Bearer ${props.authToken}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
          }
    
          return fetch(process.env.REACT_APP_REFLEBULA + `/a1/tga/event_series/${eSeries.id}`, requestOptions)
          .then(res => res.json())
          .then((res) => {
            if (res.result && res.result === `success`) {
                setAdminDialog(false)
                return true;
            } else {
                console.error(`Backend Error in handleEventDelete`);
                res.reason && console.error(`Reason: ${res.reason}`);
                return false;
            }
          })
          .catch((reason) => {
              console.error('Fetch Exception in handleEventDelete')
              console.error(`Reason: ${reason}`)
              return false;
          })
        }
    }

    const fakeRsvpToggle = (eid: number) => {
        //Hackiness for now
        return Promise.resolve(false);
    }

    return(
        <div>
            <CollapsableContent title={props.title} startingIsCollapsed={props.startingIsCollapsed}>
                <button onClick={() => enableAdminDialog()}>To Event Host Panel</button>
            </CollapsableContent>
            <dialog style={{zIndex:20, height:'75%', position:'absolute', top:100, width:'60%'}} open={adminDialog}>
                    <EventForm 
                        onCancel={()=>setAdminDialog(false)}
                        event={props.event}
                        loggedInUser={props.loggedInUser} 
                        authToken={props.authToken}
                        onRSVPToggle={fakeRsvpToggle}
                        onSubmit={handleEventFormSubmit}
                        onDelete={handleEventDelete}
                        readonly={false}
                        disableRsvp={true}
                    />
            </dialog>
        </div>
    )
}

export default CollapsableAdmin;